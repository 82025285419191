[data-sidebar-position='fixed'][data-layout='vertical'] {
  .nav-header {
    position: fixed;
    // left: auto;
    border-top-left-radius: 0;
    border-top-right-radius: 2.5rem;
  }

  .deznav {
    position: fixed;
    // z-index: 0;
    .deznav-scroll {
      border-top-left-radius: 0rem;
      border-top-right-radius: 0rem;
    }
    //border-bottom-left-radius: 0;
    //border-bottom-right-radius: 0;
  }

  .menu-toggle {
    .deznav {
      position: fixed;
    }
  }
}
[data-layout='vertical'] {
  .nav-header {
    border-top-left-radius: 0;
    border-top-right-radius: 2.5rem;
  }

  .deznav {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 1.25rem;
  }
}
[data-sidebar-style='icon-hover'][data-layout='vertical'][data-container='wide-boxed'],
[data-sidebar-style='icon-hover'][data-layout='vertical'][data-container='boxed'],
[data-header-position='fixed'][data-sidebar-position='fixed'][data-sidebar-style='icon-hover'][data-layout='vertical'][data-container='wide-boxed'],
[data-header-position='fixed'][data-sidebar-position='fixed'][data-sidebar-style='icon-hover'][data-layout='vertical'][data-container='boxed'],
[data-sidebar-style='overlay'][data-layout='vertical'][data-container='wide-boxed'],
[data-sidebar-style='overlay'][data-layout='vertical'][data-container='boxed'],
[data-header-position='fixed'][data-sidebar-position='fixed'][data-sidebar-style='overlay'][data-layout='vertical'][data-container='wide-boxed'],
[data-header-position='fixed'][data-sidebar-position='fixed'][data-sidebar-style='overlay'][data-layout='vertical'][data-container='boxed'] {
  .deznav {
    position: absolute;
  }
}
