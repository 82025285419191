[data-theme-version='dark'] {
  .footer {
    background-color: $dark-card;

    .copyright {
      background-color: transparent;

      p {
        color: $white;
      }
    }
  }
}
