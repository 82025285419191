.flot-chart {
  height: 15.7rem;
}

.tooltipflot {
  background-color: transparent;
  font-size: 1.4rem;
  padding: 0.5rem 1rem;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 0.2rem;
}

.legendColorBox > div {
  border: 0 !important;
  padding: 0 !important;
}

.legendLabel {
  font-size: 0.825rem;
  padding-left: 0.5rem;
  color: #000;
  @at-root [data-theme-version='dark'] & {
    color: $white;
  }
}

.flotTip {
  background: $black;
  border: 0.0625rem solid $black;
  color: $white;
}

.legend > div {
  background: transparent !important;
}

#balance_graph {
  height: 16.25rem;
}
