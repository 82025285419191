.alert {
  border-radius: $radius;
  padding: 1rem 1.5rem;
  p {
    line-height: 1.5;
  }
}
.alert-square {
  border-radius: 0;
}

.alert-rounded {
  border-radius: 1.875rem;
}

// Basic Alert

.alert-primary {
  background: var(--rgba-primary-1);
  border-color: var(--rgba-primary-1);
  color: var(--primary);
  @at-root [data-theme-version='dark'] & {
    background: var(--rgba-primary-1);
    border-color: var(--rgba-primary-1);
  }
}

.alert-secondary {
  background: $secondary-light;
  border-color: $secondary-light;
  color: $secondary;
  @at-root [data-theme-version='dark'] & {
    background: $secondary-opacity;
    border-color: $secondary-opacity;
    color: $white;
  }
}

.alert-success {
  background: $success-light;
  border-color: $success-light;
  color: $success;
  @at-root [data-theme-version='dark'] & {
    background: $success-opacity;
    border-color: $success-opacity;
  }
}

.alert-warning {
  background: $warning-light;
  border-color: $warning-light;
  color: $warning;
  @at-root [data-theme-version='dark'] & {
    background: $warning-opacity;
    border-color: $warning-opacity;
  }
}

.alert-danger {
  background: $danger-light;
  border-color: $danger-light;
  color: $danger;
  @at-root [data-theme-version='dark'] & {
    background: $danger-opacity;
    border-color: $danger-opacity;
  }
}

.alert-info {
  background: lighten($info, 35%);
  border-color: lighten($info, 35%);
  color: $info;
  @at-root [data-theme-version='dark'] & {
    background: $info-opacity;
    border-color: $info-opacity;
  }
}

.alert-dark {
  background: lighten($dark, 50%);
  border-color: lighten($dark, 50%);
  color: $dark;
  @at-root [data-theme-version='dark'] & {
    background: $dark-opacity;
    border-color: $dark-opacity;
    color: $white;
  }
}
.alert-light {
  background: $light;
  border-color: $light;
  color: $dark;
}
.alert {
  .btn-close {
    background: none;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    color: inherit;
    i {
      transform: scale(1.4);
    }
  }
}

// Alert alt

.alert-alt.alert-primary {
  border-left: 0.25rem solid var(--primary);
}

.alert-alt.alert-secondary {
  border-left: 0.25rem solid $secondary;
}

.alert-alt.alert-success {
  border-left: 0.25rem solid $success;
}

.alert-alt.alert-warning {
  border-left: 0.25rem solid $warning;
}

.alert-alt.alert-danger {
  border-left: 0.25rem solid $danger;
}

.alert-alt.alert-info {
  border-left: 0.25rem solid $info;
}

.alert-alt.alert-dark {
  border-left: 0.25rem solid $dark;
}
.alert-alt.alert-light {
  border-left: 0.25rem solid darken($light, 15%);
}

// Alert alt with solid color

.alert-alt.alert-primary.solid {
  border-left: 0.25rem solid var(--primary-dark) !important;
}

.alert-alt.alert-secondary.solid {
  border-left: 0.25rem solid darken($secondary, 25%) !important;
}

.alert-alt.alert-success.solid {
  border-left: 0.25rem solid darken($success, 25%) !important;
}

.alert-alt.alert-warning.solid {
  border-left: 0.25rem solid darken($warning, 25%) !important;
}

.alert-alt.alert-danger.solid {
  border-left: 0.25rem solid darken($danger, 25%) !important;
}

.alert-alt.alert-info.solid {
  border-left: 0.25rem solid darken($info, 25%) !important;
}

.alert-alt.alert-dark.solid {
  border-left: 0.25rem solid darken($dark, 25%) !important;
}
.alert-alt.alert-light.solid {
  border-left: 0.25rem solid darken($light, 25%) !important;
}

//Alert dismissable with solid color
.alert-dismissible.solid {
  .close {
    &:hover {
      color: $white;
      opacity: 1;
    }
  }
}

// Alert Solid
.alert.alert-primary.solid {
  background: var(--primary);
  color: $white;
  border-color: var(--primary);
}

.alert.alert-secondary.solid {
  background: $secondary;
  color: $white;
  border-color: $secondary;
}
.alert.alert-success.solid {
  background: $success;
  color: $white;
  border-color: $success;
}

.alert.alert-warning.solid {
  background: $warning;
  color: $white;
  border-color: $warning;
}

.alert.alert-danger.solid {
  background: $danger;
  color: $white;
  border-color: $danger;
}

.alert.alert-info.solid {
  background: $info;
  color: $white;
  border-color: $info;
}

.alert.alert-dark.solid {
  background: $dark;
  color: $white;
  border-color: $dark;
}

.alert.alert-light.solid {
  background: $light;
  color: $dark;
  border-color: $light;
}

// Alert right icon

.alert-right-icon {
  & > span i {
    font-size: 1.125rem;
    margin-right: 0.3125rem;
  }
  .close {
    i {
      font-size: 1rem;
    }
  }
}

// Alert Outline
.alert.alert-outline-primary {
  background: transparent;
  color: var(--primary);
  border-color: var(--primary);
}

.alert.alert-outline-secondary {
  background: transparent;
  color: $body-color;
  border-color: $secondary;
}

.alert.alert-outline-success {
  background: transparent;
  color: $success;
  border-color: $success;
}

.alert.alert-outline-info {
  background: transparent;
  color: $info;
  border-color: $info;
}

.alert.alert-outline-warning {
  background: transparent;
  color: $warning;
  border-color: $warning;
}

.alert.alert-outline-danger {
  background: transparent;
  color: $danger;
  border-color: $danger;
}

.alert.alert-outline-dark {
  background: transparent;
  color: $body-color;
  border-color: $dark;
}

.alert.alert-outline-light {
  background: transparent;
  color: $dark;
  border-color: $light;
}

// Alert Social
.alert-social {
  color: $white;
  .alert-social-icon {
    align-self: center;
    margin-right: 0.9375rem;
    i {
      font-size: 2.625rem;
    }
  }
  &.facebook {
    background-color: $facebook;
  }
  &.twitter {
    background-color: $twitter;
  }
  &.linkedin {
    background-color: $linkedin;
  }
  &.google-plus {
    background-color: $google-plus;
  }
  .close {
    &:hover {
      opacity: 1 !important;
      color: $white !important;
    }
  }
}

// Alert left icon

.left-icon-big {
  .alert-left-icon-big {
    align-self: center;
    margin-right: 0.9375rem;
    i {
      font-size: 2.1875rem;
      line-height: 1;
    }

    // &.primary{
    //     background: $primary;
    //     color: $white;
    // }
  }
  .media-body {
    // margin-left: 6.25rem;
  }
}
[direction='rtl'] {
  .left-icon-big .alert-left-icon-big,
  .alert-social .alert-social-icon {
    margin-right: 0;
    margin-left: 0.9375rem;
  }
}

.dz-scroll {
  overflow-y: scroll;
}

body > * {
  scrollbar-width: thin;
  scrollbar-color: rgba(111, 133, 147, 0.1) #fff0;
}
::-webkit-scrollbar {
  width: 5px;
  opacity: 0;
}

::-webkit-scrollbar-thumb {
  background: rgba(111, 133, 147, 0.1);
}
