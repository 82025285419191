// Variable.scss
.progress-bar {
  background-color: var(--primary);
}

.progress-bar-primary {
  background-color: var(--primary);
}
.progress-bar-secondary {
  background-color: var(--secondary);
}

.progress-bar-success {
  background-color: $success;
}

.progress-bar-info {
  background-color: $info;
}

.progress-bar-danger {
  background-color: $danger;
}

.progress-bar-warning {
  background-color: $warning;
}

.progress-bar-pink {
  background-color: $pink;
}

.progress {
  height: 8px;
  background-color: #f6f6f6;
  overflow: hidden;
  // box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.13);
  @at-root [data-theme-version='dark'] & {
    background-color: $d-bg;
  }
  &-bar {
    // outline: 1px solid #fff;
    border-radius: $radius;
  }
}

.progress-vertical {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;

  @include media-breakpoint-down(md) {
    margin-right: 1rem;
  }
}

.progress-vertical-bottom {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 2rem;
  min-height: 17rem;
  position: relative;
  transform: rotate(180deg);

  @include media-breakpoint-down(md) {
    margin-right: 1rem;
  }
}

.progress-animated {
  animation-duration: 5s;
  animation-name: myanimation;
  transition: all 5s ease 0s;
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}

@keyframes myanimation {
  0% {
    width: 0;
  }
}
