@media (min-width: 47.9375rem) {
  [data-sidebar-style='modern'] {
    .nav-header {
      width: 10.625rem;
      .brand-logo {
        justify-content: center;
      }
    }
    .deznav {
      //background:var(--sidebar-bg);
      .header-profile {
        margin-bottom: 0.3125rem;
        & > a.nav-link {
          display: block;
          text-align: center;
          margin: 0 -0.625rem 0.9375rem;
          padding: 0.9375rem 0.625rem;
          border-radius: $radius;
          img {
            margin-bottom: 0.3125rem;
          }
          .header-info {
            margin-left: 0 !important;
            text-align: center;
            small {
              text-align: center !important;
            }
            display: none;
          }
        }
      }
      .metismenu {
        & > li {
          text-align: center;

          & > a {
            padding: 1.25rem 0.9375rem 1.25rem 0.9375rem;
            margin: 0.125rem 0;
            // font-weight: 500;
            @include transitionMedium;

            &::after {
              display: none;
            }

            &:hover,
            &:focus,
            &:active,
            &.mm-active {
              & > a {
                background-color: var(--primary-dark);
              }
            }
          }
          &:hover,
          &.mm-active {
            padding: 0;
            & > a {
              background: var(--primary);
              color: black;
              border-radius: $radius;
              box-shadow: none;
              i {
                color: $white;
              }
              .nav-text {
                color: $white;
              }
            }
          }
          li {
            text-align: left;

            @at-root [direction='rtl']#{&} {
              text-align: right;
            }
          }
        }

        li {
          a {
            padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
            font-size: 0.9375rem;
          }
          ul:after {
            content: none;
          }

          & > ul {
            height: auto !important;
          }
        }
        .nav-label {
          display: none;
        }
      }

      .nav-label {
        display: none;
      }

      .nav-text {
        display: block;
        margin-top: 0.3125rem;
      }
      .plus-box,
      .copyright {
        display: none;
      }
      .sidebar-info {
        display: none !important;
      }
    }

    .footer {
      padding-left: 11.9rem;
    }
    .content-body {
      margin-left: 10.6rem;
    }

    &[data-layout='horizontal'] {
      .deznav {
        .metismenu {
          li {
            & > a i {
              padding: 0;
              margin: 0;
            }
          }
        }
      }
    }
  }

  [data-sidebar-style='modern'][data-layout='vertical'] {
    .deznav {
      width: 10.625rem;
      left: 0;
      padding-top: 1rem;
      @at-root [direction='rtl']#{&} {
        left: auto;
        right: 0;
      }

      .slimScrollDiv,
      .deznav-scroll {
        overflow: visible !important;
      }

      .metismenu {
        padding: 0.625rem 0.875rem;
        & > li {
          & > a {
            padding: 1.375rem 0.6em 0.9375rem;
            i {
              padding: 0;
              height: auto;
              width: auto;
              margin: 0;
              line-height: 1;
              margin-bottom: 0.3125rem;
            }
          }

          & > ul {
            display: none;
            padding: 1.875rem 0.9375rem;
          }
        }

        li {
          position: relative;

          a {
            padding: 1rem 1rem;
            &:before {
              content: none;
            }
          }

          ul {
            position: absolute;
            left: 105%;
            top: 0;
            bottom: auto;
            background-color: $white;
            border: 0.0625rem solid $border;
            width: 12.5rem;
            // box-shadow: 0.125rem 0.1875rem 0.625rem 0rem rgba(119, 119, 119, 0.1);

            @at-root [data-theme-version='dark']#{&} {
              background: $dark-card;
              box-shadow: 0rem 0rem 0.8125rem 0rem rgba(0, 0, 0, 0.1);
            }

            @at-root [direction='rtl']#{&} {
              left: auto;
              right: 105%;
            }
          }

          &:hover {
            & > ul {
              display: block;
              left: 100%;
              padding: 1rem 0;
              margin-left: 0;
              border: 0;
              box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem rgba(20, 0, 30, 0.1);
              border-radius: $radius;

              @at-root [data-theme-version='dark']#{&} {
                box-shadow: 0.3125rem 0.3125rem 1.875rem 0rem
                  rgba(20, 0, 30, 0.1);
              }

              @at-root [direction='rtl']#{&} {
                left: auto;
                right: 100%;
                box-shadow: -0.3125rem 0.3125rem 1.875rem 0rem
                  rgba(20, 0, 30, 0.1);
              }
            }
          }
        }
      }

      .nav-label {
        display: none;
      }

      .nav-text {
        display: block;
        margin-top: 0;
      }
      .header-profile2 {
        padding: 0 2.5rem;
      }
    }

    .nav-control {
      right: -4.25rem;
    }
    .header .header-content {
      padding-left: 6rem;
    }

    .menu-toggle {
      .deznav,
      .nav-header {
        left: -10.625rem;
      }
      .header {
        padding-left: 0;
      }
      .content-body {
        margin-left: 0;
      }
    }
  }

  [data-sidebar-style='modern'][data-layout='horizontal'] {
    .nav-header {
      width: 18.563rem;
    }
    .header {
      padding-left: 18.563rem;
    }
    .footer,
    .content-body {
      margin-left: 0;
    }
    .deznav {
      .metismenu {
        & > li {
          & > a {
            padding: 0.9375rem 1.5625rem 0.75rem 1.5625rem;
            margin: 0 0.125rem;
          }

          & > ul {
            top: 4.8rem;
          }
        }
      }
    }

    &[data-container='boxed'] {
      .deznav {
        .metismenu {
          & > li {
            & > a {
              padding: 0.8125rem 1.25rem;
            }
          }
        }
      }
    }
  }
}
[data-nav-headerbg='color_1'][data-headerbg='color_1'][data-sidebar-style='modern'][data-layout='horizontal'] {
  .nav-header {
    .color-title {
      display: block !important;
    }
  }
}
