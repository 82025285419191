@media (min-width: 47.9375rem) {
  [data-sidebar-style='icon-hover'][data-layout='horizontal'] {
    .header .header-content {
      padding-left: 1.875rem;
    }
  }

  [data-sidebar-style='icon-hover'][data-layout='vertical'] {
    .deznav {
      .metismenu {
        li.mm-active {
          & > ul {
            display: none;
          }
        }
      }
      .header-info2 {
        justify-content: space-between;
        img {
          order: 1;
          margin-right: 0;
        }
      }
    }
    .iconhover-toggle {
      .deznav {
        .metismenu {
          li.mm-active {
            & > ul {
              display: block;
            }
          }
        }
      }
    }

    .nav-header {
      width: 7rem;
      .brand-logo {
        padding-left: 1.875rem;
        @at-root [direction='rtl']#{&} {
          padding-left: 1.875rem;
          padding-right: 1.875rem;
        }
        .logo-abbr {
          display: block;
        }

        .brand-title {
          display: none;
        }
      }

      .nav-control {
        display: none;
      }
    }

    .header {
      padding-left: 7rem;

      @at-root [direction='rtl']#{&} {
        padding-right: 7rem;
        padding-left: 0.9375rem;
      }

      .header-content {
        padding-left: 2rem;

        @at-root [direction='rtl']#{&} {
          padding-right: 1.375rem;
          padding-left: 0;
        }
      }
    }

    .deznav {
      overflow: visible;
      position: absolute;
      left: -11.72rem;
      // z-index: 1;

      @include custommq($min: 47.9375rem, $max: 87.5rem) {
        left: -10rem;
      }

      @at-root [direction='rtl']#{&} {
        left: auto;
        right: -14.463rem;
      }

      .nav-label {
        display: none;
      }
      .header-profile {
        img {
          order: 1;
        }
        .header-info {
          margin-left: 0 !important;
          padding-left: 0 !important;
          margin-right: 0.625rem;
        }
      }
      .metismenu {
        & > li {
          padding: 0 1.5625rem;
          & > a {
            display: flex;
            justify-content: space-between;
            padding: 0.825rem 1.5rem;
            border-radius: $radius;
            margin: 5px 0;

            & > svg,
            & > i {
              order: 1;
              margin-right: 0;
              margin-top: 3px;
              padding-right: 0;
              height: auto;
              width: auto;
              line-height: 1;
              @at-root [direction='rtl']#{&} {
                padding-left: 0;
                padding-right: 0;
              }
            }
          }
          &:hover > a,
          &.mm-active > a {
            background: var(--primary);
            color: $white;
            i {
              color: $white;
            }
          }
        }

        ul {
          border-left: 0;
          padding-left: 0;
          padding-right: 0;
          @at-root [direction='rtl']#{&} {
            padding-right: 0;
            padding-left: 0;
          }
          @at-root [data-theme-version='dark']#{&} {
            border-color: $d-border;
          }
          &:after {
            left: auto;
            right: 1.75rem;
            @at-root [direction='rtl']#{&} {
              left: 1.75rem;
              right: auto;
            }
          }
          a {
            position: relative;
            padding-left: 1.3rem;
            @at-root [direction='rtl']#{&} {
              padding-right: 1.25rem;
            }
            &::before {
              left: 1.25rem;
              right: auto;

              @at-root [direction='rtl']#{&} {
                right: auto;
                left: -0.3125rem;
              }
            }
          }
        }

        .has-arrow {
          &::after {
            right: 5rem;
            opacity: 0;

            @at-root [direction='rtl']#{&} {
              right: auto;
              left: 5rem;
            }
          }
        }
      }

      .copyright,
      .plus-box {
        display: none;
      }
      &.mm-show {
        display: none;
      }
    }

    .iconhover-toggle {
      .nav-header {
        width: 18.75rem;

        .brand-logo {
          padding-left: 1.6rem;

          .brand-title {
            display: block;
          }
          .color-title {
            display: none;
          }
        }
        @include respond('laptop') {
          width: 17rem;
        }
      }
      &.mm-show {
        display: block;
      }
      .header {
        padding-left: 3.38rem;

        @at-root [direction='rtl']#{&} {
          padding-right: 4.38rem;
          padding-left: 0.9375rem;
        }
      }

      .deznav {
        left: 0;
        //.metismenu > li > a > i{
        //	opacity:1;
        //}
        .metismenu .has-arrow:after {
          opacity: 1;
        }

        @at-root [direction='rtl']#{&} {
          left: auto;
          right: 0;
        }
      }
    }

    .content-body {
      margin-left: 7rem;

      @at-root [direction='rtl']#{&} {
        margin-left: 0;
        margin-right: 7rem;
      }
    }
    .footer {
      padding-left: 7rem;

      @at-root [direction='rtl']#{&} {
        margin-left: 0;
        margin-right: 7rem;
      }
    }
  }
}
