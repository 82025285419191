.right-area {
  display: flex;
  align-items: center;
  .btn {
    @include respond('phone') {
      padding: 0.5rem 1rem;
    }
  }
}

.feature-btn {
  width: 3rem;
  height: 3rem;
  border-radius: 0.625rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--rgba-primary-1);
  margin-right: 0.625rem;
  &:hover {
    background-color: $white;
    svg {
      path {
        fill: $white;
      }
    }
  }
}

.contact_list {
  .user-content {
    display: flex;
    justify-content: center;
    margin-bottom: 0.125rem;
    .dropdown {
      position: absolute;
      top: 20px;
      right: 20px;
    }
    .user-info {
      display: block;
      align-items: center;
      padding: 0 0.5rem;
    }
    .user-details {
      .user-name {
        font-size: 1.375rem;
        font-weight: 700;
        margin-bottom: 0;
        margin-top: 12px;
        @include custommq($max: 1350px, $min: 1200px) {
          font-size: 1.2rem;
        }
        @include respond('tab-port') {
          font-size: 1rem;
        }
        @include respond('phone') {
          font-size: 1.2rem;
        }
      }
      .mail {
        margin-top: 8px;
      }
      .number {
        font-size: 1.125rem;
        font-weight: 600;
        color: var(--primary);
        margin-top: 4px;
        margin-bottom: 0.625rem;
        @include custommq($max: 1350px, $min: 1200px) {
          font-size: 1rem;
        }
        @include respond('tab-port') {
          font-size: 0.875rem;
        }
      }
      span {
        display: block;
      }
    }
    .user-img {
      width: 6.25rem;
      min-width: 6.25rem;
      height: 6.25rem;
      border-radius: 20px;
      // overflow: hidden;
      margin-right: auto;
      margin-left: auto;
      position: relative;
      @include custommq($max: 1350px, $min: 1200px) {
        width: 4.25rem;
        min-width: 4.25rem;
        height: 4.25rem;
      }
      @include respond('tab-port') {
        width: 4.25rem;
        min-width: 4.25rem;
        height: 4.25rem;
        margin-right: 1.2rem;
      }
      @include respond('phone') {
        margin-right: 1rem;
        width: 6.25rem;
        min-width: 6.25rem;
        height: 6.25rem;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: $radius;
        object-fit: cover;
      }
      &::before {
        content: '';
        content: '';
        display: block;
        background-color: #00a389;
        border: 4px solid #fff;
        position: absolute;
        bottom: -8px;
        right: -12px;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        @at-root [data-theme-version='dark'] & {
          border: 4px solid $dark-card;
        }
      }
    }
  }
  .contact-icon {
    padding-top: 0.275rem;
    display: inline-flex;

    .icon {
      width: 3rem;
      height: 3rem;
      border-radius: $radius;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--rgba-primary-1);
      margin-right: 0.938rem;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        background-color: var(--primary);
        svg {
          path {
            fill: $white;
          }
        }
      }
      svg {
        path {
          fill: var(--primary);
        }
      }
    }
  }
}
.file-contact {
  display: flex;
  flex-wrap: wrap;
  .file-list {
    flex: 0 0 50%;
  }
  .contact_list {
    width: 100%;
  }
  &.grid {
    .file-list {
      flex: 0 0 33.33%;
      max-width: 33.33%;
      padding: 0 15px;
      margin-bottom: 0px;
      box-sizing: border-box;
    }
  }
}
.table-pagenation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.625rem;
  padding: 0 20px;
  @include respond('phone') {
    justify-content: center;
  }
}
