////////////////
// Compact Nav
////////////////

@media (min-width: 47.9375rem) {
  [data-sidebar-style='compact'] {
    .nav-header {
      width: 11.25rem;
    }
    .deznav .metismenu .has-arrow[aria-expanded='true']:after,
    .deznav .metismenu .mm-active > .has-arrow:after {
      margin-right: 0;
    }
    .deznav {
      .header-profile {
        margin-bottom: 0.3125rem;
        & > a.nav-link {
          display: block;
          text-align: center;
          border: 0;
          img {
            margin-bottom: 0.3125rem;
          }
          .header-info {
            margin-left: 0 !important;
            text-align: center;
            small {
              text-align: center !important;
            }
            display: none;
          }
        }
        .dropdown-menu {
          min-width: 11rem;
        }
        a svg {
          display: unset !important;
        }
      }
      .nav-user {
        display: none;
      }

      .metismenu {
        li {
          text-align: center;
          a {
            padding: 0.7rem 0.5rem;
            svg {
              display: block;
              padding: 0;
              background: rgba(0, 0, 0, 0.05);
              /* background:rgba($light,0.2); */
              color: rgba($black, 0.3);
              width: 3.75rem;
              height: 3.75rem;
              border-radius: $radius;
              line-height: 3.75rem;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 0.3125rem;
            }
            i {
              @include transitionMedium;
            }
          }
          ul:after {
            content: none;
          }

          & > a {
            box-shadow: none;
            font-size: 0.9375rem;
          }
        }
      }

      .plus-box,
      .copyright {
        display: none;
      }
      .copyright {
        padding: 0 1.25rem;
        margin-top: 1.25rem;
      }
      .header-info2 {
        padding: 0;
        img {
          margin-left: -1rem;
        }
      }
      .sidebar-info {
        display: none !important;
      }
      .header-profile2 {
        margin: 0 3rem;
      }
      .feature-box,
      .support-box {
        display: none;
      }
    }

    .nav-text {
      display: inline-block;
      margin-top: 0.3125rem;
    }

    .nav-label {
      &.first {
        display: none;
      }
    }

    .nav-badge {
      display: none;
    }

    .footer {
      padding-left: 12.5rem;
    }

    .content-body {
      margin-left: 10.5rem;
    }

    &[data-theme-version='dark'] {
      &[data-layout='horizontal'] {
        .deznav {
          .metismenu {
            li {
              & > a i {
                color: $white;
              }
            }
          }
        }
      }
      &[data-layout='vertical'] {
        .deznav {
          .metismenu {
            li {
              & > a i {
                //background:$d-bg;
                // color:#b3b3b3;
              }
              &.mm-active {
                & > a i {
                  background: var(--primary);
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    &[data-layout='vertical'] {
      .deznav {
        .metismenu {
          li {
            &:hover {
              & > a i {
                background: var(--rgba-primary-1);
                color: var(--primary);
              }
            }
          }
        }
      }
    }
  }
}
[data-layout='horizontal'][data-sidebar-style='compact'] {
  .footer {
    padding-left: 0;
  }
  .content-body {
    margin-left: 0;
  }
  .deznav {
    margin-bottom: 0;
  }
  .header {
    padding-left: 18.563rem;
  }
  .nav-header {
    width: 18.563rem;
    .brand-logo {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
  }
}
[data-layout='vertical'][data-sidebar-style='compact'] {
  .deznav {
    width: 11.25rem;
    padding-top: 1rem;

    .metismenu {
      ul {
        margin-left: 0;
        border: 0;
        background: rgba(0, 0, 0, 0.02);
        padding: 0.625rem 0;

        @at-root [data-theme-version='dark']#{&} {
          background: (255, 255, 255, 0.05);
        }

        a {
          &:before {
            content: none;
          }
        }

        ul a {
          padding: 0.625rem 0.9375rem;
        }
      }

      & > li {
        & > a {
          padding: 1.2rem 0.5rem;
        }
        & > a.has-arrow::after {
          top: 0.0625rem;
          display: inline-block;
          right: auto;
          margin-left: 0.3125rem;
          position: relative;
          width: 0.4375rem;
          height: 0.4375rem;
          border-width: 0.125rem 0rem 0rem 0.125rem;

          @at-root [direction='rtl']#{&} {
            left: auto;
            margin-left: 0;
            margin-right: 0.3125rem;
          }
        }
        &.mm-active {
          & > a {
            background: transparent;
            color: var(--primary);
          }
        }
      }
    }
  }
}
